import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
// import { TitleComponent } from '../../components/Title/Title';
import moment from 'moment';
import domToPdf from 'dom-to-pdf'
import Patent from './components/table';
import { HorizontalTimeline } from './components/timeline';
import "./viewPatents.css"
import base64 from 'react-native-base64';
import { Header } from '../../components/header/header';
import { Footer } from '../../components/footer/footer';



export const ViewPatent = (props) => {
    const[loadPage,setLoadPage] = useState(false)
    const[flag,setFlag] = useState(false)
    const[number,setNumber] = useState()
    const[publicationDate,setPublicationDate] = useState("")
    const[applicationDate,setApplicationDate] = useState("")
    const[priorityDate,setPriorityDate] = useState("")
    const[assignees,setAssignees] = useState("")
    const[inventors,setInventors]=useState([])
    const[industry,setIndustry]=useState("")
    const[title,setTitle] = useState()
    const[abstract,setAbstract] = useState()
    // const[description,setDescription] = useState()
    const[claims,setClaims] = useState("")
    const[similarPatents,setSimilarPatents] = useState([])
    const[startIndex,setStartIndex] = useState(0)

    const getPatentURL = "https://sytlarvvl3.execute-api.us-east-1.amazonaws.com/staging/apis/application"
    // const getSimilarPatentURL = "http://localhost:8000/semantic-search/similar-applications"
    const getSimilarPatentURL = "https://5cice31m1c.execute-api.us-east-1.amazonaws.com/production/apis/bulk-download/applications"

    useEffect(()=>{
        console.log(props)
        let publicationNumber = "fdsf"
        if (!publicationNumber.startsWith("IN")){
            publicationNumber = "US"+publicationNumber+"A1"
        }
        axios.get(getPatentURL,{params:{number:publicationNumber},"Content-Type": "application/xml; charset=utf-8"}).then((response)=>{
            
            if (response.data.errorType){
                setFlag(false)
                setLoadPage(true)
            }
            else {
                let patentData = JSON.parse(response.data)
                if (patentData.publication_number) {
                    setLoadPage(true)
                    setFlag(true)
                    setNumber(patentData.publication_number)
                    setPublicationDate(moment((patentData.publication_date),"YYYYMMDD").format("MMM Do YYYY"))
                    setApplicationDate(moment((patentData.application_date),"YYYYMMDD").format("MMM Do YYYY"))
                    setPriorityDate((patentData.priority_date)?moment((patentData.priority_date),"YYYYMMDD").format("MMM Do YYYY"):moment((patentData.application_date),"YYYYMMDD").format("MMM Do YYYY"))
                    
                    setAssignees(patentData.assignee)
                    setInventors(patentData.inventor)

                    setIndustry(patentData.industry)

                    setTitle(patentData.title)
                    setAbstract(patentData.abstract_text)
                    // setDescription(patentData.description_text)
                    setClaims(patentData.claims_text)
                    axios.get(getSimilarPatentURL,{params:{cpc:patentData.main_cpc,limit:20}}).then((response)=>{
                        setSimilarPatents(response.data.slice(Math.random(response.data.length)))
                    })
                }
            }
        })
        .catch(()=>{
            setFlag(false)
            setLoadPage(true)
        })
    },[])


    const generatePdf = () => {
        const element = document.getElementById('patentDetail')
        const options = {
            filename: number,
        }
        return domToPdf(element,options,()=>{
            console.log('Downloading patent...')
        })
    }

    const moveCarousal = (type) => {
        let newPosition = startIndex+type
        if (newPosition<0){
            newPosition=0
        }
        if (newPosition>6){
            newPosition=6
        }
        setStartIndex(newPosition)
    }

    const navigate = useNavigate()
    const handleClick = (e) => {
        // setSimilarPatents([])
        let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
        let publication_number = e.currentTarget.id
        if (base64regex.test(publication_number)) {
        publication_number = base64.decode(e.currentTarget.id,'base64')
        }
        if (publication_number.length > 11) {
            publication_number = publication_number.substring(0,publication_number.length-1)
        }
        navigate.push("/"+ publication_number + "/view-patent")
    }


    const toTitleCase = (str) => {
        if (typeof str === "string") {
          return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        }
        else if (str[0]) {
          return str[0].replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })
        }
        else {
          return str
        }
    }

    const cleanText = (str) => {
        return str.replace(/(\r\n|\n|\r)/gm, "")
    }

    return (
        <div>
            {/* <Header/> */}
            <div>
                <i className="eva eva-arrow-circle-left-outline" onClick={() => navigate.goBack()}></i>
                <i className="eva eva-save-outline" style={{float:'right'}} onClick={generatePdf}></i>   
            </div>
            {(flag)&&
            <div id='patentDetail' className="border-top" >
                <Patent headers={["Publication Number","Industry","Assignee","First Inventor"]} data = {[{"Publication Number":number,"Industry":industry,"Assignee":assignees,"First Inventor":inventors[0]}]}/>
                <br/>
                <Row  className="gutter mb-6">
                    <Col className="mb-4 mb-md-0" xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center" style={{fontWeight:'bold'}}>Application timeline</div>   
                        </div>
                        
                        {(priorityDate)&&(applicationDate)&&(publicationDate)&&
                        <HorizontalTimeline priorityDate={(priorityDate==="NA")?applicationDate:priorityDate} applicationDate={applicationDate} publicationDate={publicationDate}/>
                        }
                    </Col>
                </Row> 
                
                <Row>
                <div className="d-flex justify-content-between widget-p-md">
                    {/* <div className="d-flex align-items-center">Textual Information</div>    */}
                </div>
                </Row>
                <Row>
                    <Col className="mb-4 mb-md-0" xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center" style={{fontWeight:'bold'}}>Title</div>   
                        </div>
                        <div className="d-flex justify-content-between widget-p-md">{title}</div>
                    </Col>
                    <Col className="mb-4 mb-md-0" xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center" style={{fontWeight:'bold'}}>Abstract</div>   
                        </div>
                        <div className="d-flex justify-content-between widget-p-md" style={{whiteSpace: 'pre-line'}}>{abstract}</div>
                    </Col>
                    
                    {(claims.split("CLM-000").length>1)&&
                    <Col className="mb-4 mb-md-0" xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center" style={{fontWeight:'bold'}}>Primary Claim</div>   
                        </div>
                        <div className="d-flex justify-content-between widget-p-md" style={{whiteSpace: 'pre-line'}}>{(claims.split("CLM-000")[0].length>50)?cleanText(claims.split("CLM-000")[0])+cleanText(claims.split("CLM-000")[1]):cleanText(claims.split("CLM-000")[1])}</div>
                    </Col>
                    }
                    {/* <div>Show full claim</div>
                    <Col className="mb-4 mb-md-0" xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center" style={{fontWeight:'bold'}}>Primary Claim</div>   
                        </div>
                        <div className="d-flex justify-content-between widget-p-md" style={{whiteSpace: 'pre-line'}}>{claims}</div>
                    </Col> */}
                </Row>
                <br/>
            
                <div id="similar-patents">
                    <p className='title'>You might want to read</p>
                    <br/>
                    <div id = "carousel" className='recommendedContainer'>
                        {(similarPatents.length>2)&&
                            <div className='carouselButton' onClick={()=>{moveCarousal(-1)}}><i className="eva eva-arrow-ios-back-outline"></i></div>
                        }
                        {similarPatents.slice(startIndex,startIndex+4).map((item,index)=>
                        <div id = {item.publication_number} key={index} className='card' onClick={handleClick}>
                            <div className='date'>{item.application_date}</div>
                            <div className='applicationTitle'>{toTitleCase(item.title)}</div>
                        </div>
                        )}
                        {(similarPatents.length>2)&&
                            <div className='carouselButton' onClick={()=>{moveCarousal(1)}}><i className="eva eva-arrow-ios-forward-outline"></i></div>
                        }
                    </div>
                </div>
            </div>
            }
            {(!flag)&&(loadPage)&&
            <div className='lastPage'>
                <p>The selected application belong to a database, which is not part of your subscription.</p>
                <button className='button' onClick={()=>{window.location.href = 'https://incubig.org'}}>Sign Up for Full Access</button>
            </div>
            }
            {(!loadPage)&&
            <div>
                Loading...
            </div>
            }
            <Footer/>
        </div>
    )
}