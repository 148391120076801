import React from "react";
import { useNavigate } from "react-router-dom";
import  "./header.css";

export const Header = () => {
  const navigate = useNavigate()
  const redirectToHome = () => {
    navigate("/")
  }
  return(
    <div className='header'>
            <img className='logo d-inline-block align-text-top' src="/logo512.png" alt="logo" onClick={()=>{redirectToHome()}}/>
    </div>
  )
}

