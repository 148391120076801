import React from "react";
import  "./footer.css";

export const Footer = () => {
  return(
    <div className="footer">
      {/* <span className='footerLabel'>{(new Date().getFullYear())} &copy; <span >Incubig &#40;A Texmin Incubatee&#41;</span></span> */}
      <span className="footerLabel">Incubig | Research Intelligence | Backed by texmin &copy; {(new Date().getFullYear())}</span>
    </div>
  )
}

