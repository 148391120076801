import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom';
import { SearchSection } from '../../components/searchSection/searchSection'
import { Logo } from '../../components/logo/logo'
import {Footer} from "../../components/footer/footer"

import "./landingPage.css"

export const LandingPage = () => {
    const[query,setQuery] = useState()
    const[emergingIndustries, setEmergingIndustries] = useState(["Quantum Computing","Subterranean Drilling","Lithium Ore Extraction","Artificial Intelligence","Virtual and Augmented Reality","Biotechnology and Gene Editing","Reneweble Energy","E-commerce and online marketplaces"])
    const[recentSearches,setRecentSearches] = useState()
    const[keyPlayers,setKeyPlayers] = useState([{"org":"SAMSUNG ELECTRONICS CO., LTD.","count":193,"previousCount":222},{"org":"CANON KABUSHIKI KAISHA","count":72,"previousCount":55},{"org":"INTERNATIONAL BUSINESS MACHINES CORPORATION","count":71,"previousCount":64},{"org":"SAMSUNG DISPLAY CO., LTD.","count":69,"previousCount":68},{"org":"QUALCOMM Incorporated","count":67,"previousCount":95},{"org":"Dell Products L.P.","count":51,"previousCount":33},{"org":"HUAWEI TECHNOLOGIES CO., LTD.","count":50,"previousCount":67},{"org":"EMC IP Holding Company LLC","count":46,"previousCount":25},{"org":"Monsanto Technology LLC","count":41,"previousCount":24},{"org":"TOYOTA JIDOSHA KABUSHIKI KAISHA","count":36,"previousCount":42}])

    const emergingIndustriesURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent"
    const recentSearchesURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/recent"
    const keyPlayersURL = "https://09rypb92vg.execute-api.ap-south-1.amazonaws.com/alpha/api/v1/top-orgs"

    useEffect(()=>{
        axios.get(recentSearchesURL).then((response)=>{
            setRecentSearches(response.data)
        })
        axios.get(keyPlayersURL).then((response)=>{
            setKeyPlayers(response.data.slice(0,5))
        })

    },[])

    const navigate = useNavigate()

    const moveToResultPage = (e) => {
        navigate('/filter-results',{
            state:{
                query:  e,
                cache: true,
                type: "assignee_country"
            }
        })
    }

    const toTitleCase = (str) => {
        if (typeof str === "string") {
            return str.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
          }
          else if (str[0]) {
            return str[0].replace(
              /\w\S*/g,
              function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
          }
          else {
            return str
        }
    }


    return(
        <div className='landingPage'>
            <Logo/>
            <SearchSection/>
            <div className='section'>
                <div className='heading'>Emerging Industries</div>
                <br/>
                <div className='emergingSection'>
                    {emergingIndustries.map((item,index)=>
                        <div key={index} className='emergingText' onClick={()=>{moveToResultPage(item)}}>{item}</div>
                    )}
                </div>
            </div>
            <br/>
            <br/>
            <div className='splitSection'>
                <div className='halfScreen'>
                    <div className='sectionHeading'>Recent Queries</div>
                    <br/>
                    {(typeof recentSearches != "undefined")
                ?
                    <div>
                        {recentSearches.map((item,index)=>
                        <div key={index} className='searchRow' onClick={()=>{moveToResultPage(item.query)}}>
                            <div className='query'>{toTitleCase(item.query)}</div>
                            <div className='industry'>{item.industry}</div>
                            
                        </div>
                        )}
                    </div>
                :
                    <Skeleton count={5}/>
                    }
                </div>
                
                <div className='halfScreen'>
                    <div className='sectionHeading'>Global Innovation Index</div>
                    <br/>
                    {(typeof keyPlayers != "undefined")
                ?
                    <div>
                        {keyPlayers.slice(0,5).map((item,index)=>
                        <div key={index} className='searchRow'>
                            <div className='query'>{toTitleCase(item.org)}</div>
                            <div className='industry'>{item.count} new applications this week</div>
                            
                        </div>
                        )}
                    </div>
                :
                    <Skeleton count={5}/>
                    }
                </div>
                
            </div>
            <div style={{height:90}}>

            </div>
            <div style={{height:90, color:"white"}}>
                .
            </div>
            <Footer/>
        </div>
    )
}