import React, { useState } from "react";
import {
  Col,
  Row,
  Table,
} from "reactstrap";
// import Widget from "../../../components/Widget/Widget.js";


import "./table.css";

const Patent = function (props) {
  const[headers] = useState(props.headers)
  const[]=useState(props.data)


  const toTitleCase = (str) => {
    if (typeof(str)=="string") {
      return str.replace(
        /\w\S*/g,
        function(txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      )
    }
    else {
      return str
    }
  }


  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <div>
                <div className="widget-table-overflow">
                  <Table className={`table-striped table-borderless table-hover ${s.statesTable}`} responsive>
                    <thead>
                    <tr>
                      {headers.map((item,index)=>
                      <th key={index} className="headline-3">{item}</th>
                      )}
                    </tr>
                    </thead>
                    <tbody>
                    {props.data.map((item,index) => (
                        <tr key={index} >
                            {headers.map((i,j)=>
                                <td key ={j} className="body-3 muted">{toTitleCase(item[headers[j]])}</td>
                            )}
                        </tr>
                      ))} 
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default Patent;
