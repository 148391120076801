import React, { useState} from "react";
import {
  Col,
  Row,
  Table,
} from "reactstrap";

import "./table.module.css";
import base64 from "react-native-base64";
import { useNavigate } from 'react-router-dom';

const ApplicationsData = function (props) {
  const[headers] = useState(props.headers)
  const[clickableField] = useState(props.clickableField)

  const navigate = useNavigate()
  const handleClick = (e) => {
    let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    let publication_number = e.currentTarget.id
    if (base64regex.test(publication_number)) {
      publication_number = base64.decode(e.currentTarget.id,'base64')
    }
    
    publication_number = publication_number.substring(0,publication_number.length-1)
    navigate("/application/"+ publication_number + "/view-patent")
  }
  
  const toTitleCase = (str) => {
    let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    let dateRegex = /[0-9]{8}/
    if (typeof str === "string") {
      if (base64regex.test(str)) {
        return base64.decode(str,'base64')
      }
      return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    }
    else if (typeof str==="object") {
      return str.toString()
    }
    else {
      let dateString = str.toString()
      if (typeof str === 'number' && str.toString().length===8){
        return (dateString.slice(0,4)+"-"+dateString.slice(4,6)+"-"+dateString.slice(6))
      }
      else {
        return str
      }
    }
  }

  const toHeading = (str) => {
    if (typeof str === "string") {
      str = str.replace("_"," ")
      return str[0].toUpperCase()+str.slice(1,str.length).toLowerCase()
    }
    else {
      return str
    }
  }


  return (
    <div>
      <Row>
        <Col>
          <Row>
            <Col>
              <div>
                <div>
                  <Table className={`table-striped table-borderless table-hover statesTable`} responsive>
                  <thead style={{backgroundColor: "#f7f8fb", fontWeight: "bold", textAlign: "left"}}>
                    <tr>
                      {headers.map((item,index)=>
                      <th key={index} className="headline-3">{toHeading(item)}</th>
                      )}
                    </tr>
                    </thead>
                    {(typeof clickableField == "undefined")?
                    <tbody>
                    {props.data.map((item,index) => (
                        <tr key={index} style={{
                          fontSize: "16px",
                          // paddingright: "12px",
                          padding: "10px",
                          // paddingBottom: "10px",
                          backgroundColor: "#f7f8fb",
                          borderBottom: "1px dotted rgba(20, 195, 155, 0.2)"
                        }}>
                            {headers.map((i,j)=>
                                <td key ={j} className="body-3 muted">{toTitleCase(item[headers[j]])}</td>
                            )}
                        </tr>
                      ))} 
                    </tbody>
                    :
                    <tbody>
                    {props.data.map((item,index) => (
                        <tr id={item[clickableField]} key={index} onClick={handleClick} style={{cursor:"pointer"}}>
                            {headers.map((i,j)=>
                                <td key ={j} style={{
                                  fontSize: "16px",
                                  // paddingright: "12px",
                                  padding: "10px",
                                  // paddingBottom: "10px",
                                  backgroundColor: "#f7f8fb"
                                }} className="body-3 muted">{toTitleCase(item[headers[j]])}</td>
                            )}
                        </tr>
                      ))} 
                    </tbody>
                    }
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default ApplicationsData;
