import React, { useEffect, useState } from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import { BsSearch, BsSend } from "react-icons/bs"
import TwoLineChart from "../../components/Charts/twoLineChart"
import ApplicationsData from '../../components/Charts/table'
import { DownloadAllApplications } from '../../components/exportToCSV/exportToCSV'
import Skeleton from 'react-loading-skeleton'
import { Col, Row } from 'reactstrap'
import { Rings } from 'react-loader-spinner'
import axios from 'axios';
import "./resultsPage.css"
import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';

export const SearchResults = (props) => {
    const location = useLocation()
    const[query,setQuery] = useState(location.state.query)
    const[trends,setTrends] = useState()
    const[keyApplications,setKeyApplications] = useState()
    const[applicationCount,setApplicationCount] = useState(0)
    const[loadMoreText,setLoadMoreText] = useState("Load More")

    const[searchFacets,setSearchFacets] = useState()

    const searchURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/clients/analyze-research-area"
    const cachedDataURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/cache/results"

    useEffect(()=>{
        if (location.state.cache) {
            cachedSearch()
        }
        else {
            directSearch(createFilters(location.state.type,location.state.name))
        }
    },[])

    const directSearch = (filter=null) => {
        axios.get(searchURL,{params:{query:query,filter:filter}}).then((response)=>{
            setApplicationCount(response.data.applications.length)
            setKeyApplications(response.data.applications)
            setTrends(response.data.trends)
        })
    }

    const cachedSearch = () => {
        axios.get(cachedDataURL,{params:{query:location.state.query}}).then((response)=>{
            if (response.data.length==0){
                directSearch()
            }
            else{
                setApplicationCount(response.data.results.length)
                setKeyApplications(response.data.results)
                setTrends(response.data.trends)
            }
            
        })
    }

    const getInput = (e) => {
        setQuery(e.target.value)
    }

    const handleEnter = (e) => {
        if (e.key==="Enter"){
            directSearch()
        }
    }

    const createFilters = (filter,value) => {
        if (location.state.type&&location.state.name){
            if (filter === "assignee"){
                return "(assignee/any(p:p eq '"+value+"'))"
            }
            else if (filter === "inventor") {
                return "(inventor/any(p:p eq '"+value+"'))"
            }
            else{
                return "("+filter+" eq '"+value+"')"
            }
        }
        else {
            return null
        }
    }

    const loadMore = () => {
        setLoadMoreText("Loading, please wait...")
        let tempApplicationCount=applicationCount+10
        let filter = createFilters(location.state.type,location.state.name)
        setApplicationCount(tempApplicationCount)
        axios.get(searchURL,{params:{query:query,filter:filter,skip:applicationCount, return_limit:tempApplicationCount}}).then((response)=>{
            setKeyApplications(keyApplications.concat(response.data.applications))
            setTrends(response.data.trends)
            setLoadMoreText("Load More")
        })
        
    }

    const navigate = useNavigate()
    const moveToFilterPage = () => {
        navigate('/filter-results',{
            state:{
                query:  query,
                cache: true,
                type: "assignee_country"
            }
        })
    }

    const sanitizeText = (str) => {
        if (typeof str === "string") {
            str = str.replace("_"," ")
            return str[0].toUpperCase()+str.slice(1,str.length).toLowerCase()
          }
          else {
            return str
          }
    }
    

    return(
        <div className='results'>
            <Header/>
            <div>
                <div className='container'>
                        {/* <div className='brandName'>DO YOUR RESEARCH</div> */}
                        {/* <input className='searchBar' placeholder="Enter your query here" onChange={(e)=>{getInput(e)}} onKeyUp={(e)=>{handleEnter(e)}} defaultValue={query}/>
                        <button className='button' onClick={()=>{moveToFilterPage()}}>Search</button> */}
                </div>
                <p>Analysis of patents related to {query} {(typeof location.state.name !="undefined")&&<span>in {location.state.name}</span>}</p>
                <br/>
                <div id="resultsSection">
                    <Row  className="gutter mb-6">
                        <Col xs={12} md={12}>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center sectionHeading">Patent filing activity</div>
                            </div>
                            {(typeof trends !="undefined")
                            ?
                                <TwoLineChart series={trends}/>
                            :
                            <Rings color="#00BFFF" height={80} width={80} />
                            }
                        </Col>
                    </Row>

                </div>
                <div id="resultsSection">
                    <Row  className="gutter mb-6">
                        <Col xs={12} md={12}>
                        <div className="d-flex justify-content-between widget-p-md">
                            <div className="d-flex align-items-center sectionHeading">Patent applications similar to your search</div>
                        </div>
                        {(typeof keyApplications!="undefined")?
                        <div>
                            <ApplicationsData headers={["title",(location.state.type==="assignee"?"inventor":"assignee"),"application_date"]} data={keyApplications} clickableField="publication_number"/>
                            
                            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <button  className='button' onClick={()=>{loadMore()}}>{loadMoreText}</button>
                            </div>
                            
                            <DownloadAllApplications searchData={true} searchURL={searchURL} searchParams={{query:query,filter:createFilters(location.state.type,location.state.name)}} fileName={"Incubig_AI_"+location.state.name+"_patents"} name={location.state.name}/>
                        </div>
                        :
                        <Rings color="#00BFFF" height={80} width={80} />
                        }
                        </Col>
                    </Row>

                    <br/>
                </div>
            </div>
            <Footer/>
        </div>
    )
}