import React from 'react'
import { Chrono } from "react-chrono";

export const HorizontalTimeline = (props) => {
    const items = [{"title":props.priorityDate,"cardTitle":"Priority","cardSubtitle":"Application takes priority from this date"},{"title":props.applicationDate,"cardTitle":"Filed","cardSubtitle":"Application was filed by assignee on this date"},{"title":props.publicationDate,"cardTitle":"Published","cardSubtitle":"USPTO published the application to public"}]
    return(
        <div>
            <Chrono
                items={items}
                // mode="HORIZONTAL"
                // slideShow={true}
                // // itemWidth={"250"}
                hideControls={true}
                cardHeight={50}
                borderLessCards={true}
                // theme={{
                //     primary: "#01bf71",
                //     secondary: "#010606",
                //     cardBgColor: "#f7f8fa",
                //     cardForeColor: "#010606",
                //     titleColor: "#fff",
                // }}
            >

            </Chrono>
      </div>
    )
}