import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Footer } from '../../components/footer/footer'
import { Rings } from 'react-loader-spinner'
import './filterData.css'

export const FilterData = (props) => {
    const[filterType,setFilterType] = useState()
    const[filters,setFilters] = useState({"US":448,"KR":268,"CN":75,"JP":57,"TW":30})
    const[players,setPlayers] = useState({"LG ELECTRONICS INC.":135,"SAMSUNG ELECTRONICS CO., LTD.":55,"International Business Machines Corporation":47,"Samsung Electronics Co., Ltd.":21,"BEIJING BAIDU NETCOM SCIENCE AND TECHNOLOGY CO., LTD.":19})
    const[inventors,setInventors] = useState({"Jonghoon CHAE":28,"Kenneth Neumann":24,"Jichan MAENG":17,"Jaehong KIM":16,"Jaehwan KIM":14})

    const location = useLocation()

    const searchURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/clients/analyze-research-area"

    useEffect(()=>{
        axios.get(searchURL,{params:{query:location.state.query}}).then((response)=>{
            
            setFilters(response.data.geographies)
            setPlayers(response.data.keyPlayers)
            setInventors(response.data.inventors)
        })
    },[])


    const navigate = useNavigate()
    const moveToResultsPage = (name,type="assignee_country") => {
        navigate('/results',{
            state:{
                query:  location.state.query,
                cache: false,
                type: type,
                name: name
            }
        })
    }

    return(
        <div className='filterData'>
            <div className='container'>
                <div className='brandName'>{location.state.query}</div>
            </div>
            <br/>
            <p className='heading'>Research Trends in {location.state.query}</p>
            <br/>
            {(typeof filters!="undefined")
            ?
            <div className='cardsContainer' style={{display:"flex"}}>
                {Object.keys(filters).map((item, index)=>
                <div key={index} className='card' onClick={()=>{moveToResultsPage(item)}}>
                    <div className='cardTitle'>{item}</div>
                    <div className='cardImg'><img src={'/assets/countries/'+item+'.png'}/></div>
                    <div className='cardSubTitle'>{filters[item]} applications</div>
                </div>
                )}
            </div>
            :
            <Rings color="#00BFFF" height={80} width={80} />
            }
            <br/>
            <br/>
            <div className='splitSection'>
                <div className='halfScreen'>
                    <div className='sectionHeading'>People Innovating</div>
                    <br/>
                    {(typeof inventors != "undefined")&&
                    <div>
                        {Object.keys(inventors).slice(0,5).map((item,index)=>
                        <div key={index} className='searchRow' onClick={()=>{moveToResultsPage(item,"inventor")}} style={{display:"flex"}}>
                            <div className='industry' style={{textAlign:"left"}}>{item}</div>
                            <div className='query' style={{textAlign:"right"}}>{inventors[item]} applications</div>
                        </div>
                        )}
                    </div>
                    }
                </div>
                <div className='halfScreen'>
                    <div className='sectionHeading'>Key Players</div>
                    <br/>
                    {(typeof players != "undefined")&&
                    <div>
                        {Object.keys(players).slice(0,5).map((item,index)=>
                        <div key={index} className='searchRow' onClick={()=>{moveToResultsPage(item,"assignee")}} style={{display:"flex"}}>
                            <div className='industry'  style={{textAlign:"left"}}>{item}</div>
                            <div className='query'  style={{textAlign:"right"}}>{players[item]} applications</div>
                        </div>
                        )}
                    </div>
                    }
                </div>
            </div>
            <br/>
            <p className='skip' onClick={()=>{moveToResultsPage(null)}}>skip and see all data</p>
            <div style={{height:90}}>

            </div>
            <div style={{height:90, color:"white"}}>
                .
            </div>
            <Footer/>
        </div>
    )
}