import React from 'react'
import './logo.css'

export const Logo = () => {
    return(
        <div className='brandLine row'>
            <img className='logo d-inline-block align-text-top' src="/logo512.png" alt="logo"/>
            <p className='logoText'>INCUBIG AI</p>
        </div>
    )
}