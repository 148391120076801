import React, { useState } from 'react'
import { BsSearch, BsSend } from "react-icons/bs"
import axios from 'axios'
import "./searchSection.css"
import { useNavigate } from 'react-router-dom'

export const SearchSection = () => {
    const[query,setQuery] = useState()

    const searchURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/clients/analyze-research-area"


    const getInput = (e) => {
        setQuery(e.target.value)
    }

    const handleEnter = (e) => {
        if (e.key==="Enter"){
            moveToResultPage()
        }
    }

    const navigate = useNavigate()
    const moveToResultsPage = (e) => {
        navigate('/filter-results',{
            state:{
                query:  query,
                cache: true,
                type: "assignee_country"
            }
        })
    }

    return(
        <div>
            <div className='searchBox'>
                <div className='container'>
                    <div className='brandName'>DO YOUR RESEARCH</div>
                    <input className='searchBar' placeholder="Enter your query here" onChange={(e)=>{getInput(e)}} onKeyUp={(e)=>{handleEnter(e)}}/>
                    <button className='button' onClick={()=>{moveToResultsPage()}}>Search</button>
                </div>
                {/* <span className="icon"><BsSearch/></span>
                <span className='sendIconWrapper'><BsSend className="sendIcon" onClick={()=>{search()}}/></span> */}
            </div>
        </div>
    )

}