import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LandingPage } from './pages/landingPage/landingPage';
import { SearchResults } from './pages/resultsPage/resultsPage';
import { ViewPatent } from './pages/viewPatent/viewPatent';
import { FilterData } from './pages/filterData/filterData';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/results" element={<SearchResults />}/>
        <Route path="/filter-results" element={<FilterData />}/>
        <Route path="/application/:number/view-patent" element={<ViewPatent />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
