import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";


export default function TwoLineChart(props) {
  const[labels] = useState(props.series.labels)
  const[series1data,setSeries1data] = useState([])
  const[series2data,setSeries2data] = useState([])

  useEffect(()=>{
    if ("count" in props.series) {
        let tempSeries1 = []
        let tempSeries2 = []
        props.series.count.map((item)=>{
            tempSeries1.push(item[0])
            tempSeries2.push(item[1])
        })
        setSeries1data(tempSeries1.slice(-12))
        setSeries2data(tempSeries2.slice(-12))
    }
  },[])
  
  const series = [
    {
      name: "Filing Trends",
      data: series1data,
    },
    {
        name: "Publication Trends",
        data: series2data,
      }
  ];
  
  const chartSettings = {
    labels:labels.slice(-12),
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    grid: {
      show:false,
    },
    xaxis: {
        show:true,  
        labels:{
        show: true,
        }
    },
    yaxis: {
      show:false,
    },
    
    fill: {
      type: "gradient",
    },
    colors: ["#4D53E0", "#41D5E2"],
    chart: {
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
      horizontalAlign: "center",
    },
  };

  return (
    <div>
      <ApexCharts
        options={chartSettings}
        series={series}
        type="area"
        height={100}
      />
    </div>
  );
}
