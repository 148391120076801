import React, { useState } from 'react'
import { ExportToCsv } from 'export-to-csv';
import { Button } from 'reactstrap';
import axios from 'axios';

import './exportToCSV.css'

const options = { 
    fieldSeparator: '\t',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true, 
    showTitle: true,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true
}

export const DownloadAllApplications = (props) => {
    const[flag,setFlag] = useState(true)

    const url = "https://5cice31m1c.execute-api.us-east-1.amazonaws.com/production/apis/bulk-download/applications"
    const searchURL = "https://b1626ymle2.execute-api.ap-south-1.amazonaws.com/production/clients/analyze-research-area"

    let org = props.org
    let cpc = props.cpc
    let inventor = props.inventor
    let limit = props.limit
    
    const handleClick = () => {
        setFlag(false)
        if (props.searchData){
            axios.get(props.searchURL,{params:props.searchParams}).then((response)=>{
                options["title"] = props.name + "'s patent applications related to your research area"
                options["filename"] = props.fileName
                let csvExporter = new ExportToCsv(options)
                csvExporter.generateCsv(response.data.applications)
                setFlag(true)
            })
        }
        else {
            axios.get(url,{params:{"org":org,"cpc":cpc,"inventor":inventor,"limit":limit}}).then((response)=>{
                let csvExporter = new ExportToCsv(options)
                csvExporter.generateCsv(response.data)
                setFlag(true)
            })
        }
    }
    
    return (
        <div>
            {(flag)?
                <div>
                    {(props.icon)
                    ?
                    <i className="eva eva-download-outline" style={{float:'right'}} onClick={()=>{handleClick()}}></i>  
                    :
                    <button className='button' onClick={handleClick}>Download All Applications</button>
                    }
                </div>
                :
                <p style={{color:"blue"}}>Downloading {limit} patent applications, please wait</p>
            }
        </div>
    )
}